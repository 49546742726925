import * as React from "react";
import Button from "../../components/form/Button";
import { TitleHeading } from "../../components/typography/TitleHeading";
import Editor from "@monaco-editor/react";
import "react-quill/dist/quill.snow.css";
import { twMerge } from "tailwind-merge";
import { jwtDecode } from "jwt-decode";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import { useConfig } from "../../hooks/useConfig";
import { GtmEven, usePushGtmEvent } from "../../hooks/useGmtPushEvent";
import _ from "lodash";

const MAX_MINUTES = 120;
const START_TIMER_SLIDE_INDEX = 7;

const useIsSSR = () => {
  const [isSSR, setIsSSR] = React.useState(true);
  React.useEffect(() => setIsSSR(false), []);
  return isSSR;
};

const StoreKeys = [
  "General1",
  "General2",
  "General3",
  "General4",
  "General5",
  "FooBar",
  "ArrayCount",
  "Star1",
  "Star2",
  "Star3",
  "Star4",
  "SystemDesign",
] as const;
type StoreKeys = (typeof StoreKeys)[number];

function useStoreResult<T>(props: {
  title: string;
  description: string;
  storeKey: StoreKeys;
  kind: string;
  meta?: unknown;
}) {
  const isSSR = useIsSSR();
  const defaultValue = React.useMemo(() => {
    if (isSSR) return "";
    const stored = localStorage.getItem(props.storeKey);
    return stored ? JSON.parse(stored).value : "";
  }, [isSSR, props.storeKey]);

  const onChange = (value: T | undefined | null, meta?: unknown) => {
    if (!value) localStorage.removeItem(props.storeKey);
    else
      localStorage.setItem(
        props.storeKey,
        JSON.stringify({
          title: props.title,
          description: props.description,
          kind: props.kind,
          meta: meta ?? props.meta,
          value,
          timestamp: Date.now(),
        }),
      );
  };

  return [defaultValue, onChange] as const;
}

function Slide(props: {
  tag: string;
  title: string;
  description: string;
  dark?: boolean;
  content?: React.ReactNode;
  next?: () => void;
  nextText?: string;
  prev?: () => void;
}) {
  return (
    <div
      className={twMerge(
        "relative h-full w-screen shrink-0 overflow-hidden flex flex-col ",
        props.dark ? "bg-gray-900 text-gray-300" : "bg-gray-100 text-gray-600",
      )}
    >
      <div className="w-full h-full overflow-y-scroll">
        <div className="mx-auto container w-full pt-10 pb-24 shrink-0 flex items-center min-h-full">
          <div className="w-full">
            <TitleHeading
              tag={props.tag}
              title={props.title.toUpperCase()}
              theme={props.dark ? "dark" : "gray"}
            />
            <p
              className="font-mono mb-8 mt-4 max-w-6xl text-lg leading-snug"
              dangerouslySetInnerHTML={{ __html: props.description }}
            />
            {props.content}
          </div>
        </div>
      </div>
      {(props.next || props.prev) && (
        <div className="absolute bottom-0 left-0 right-0 w-full flex space-x-3 px-8 py-4 z-50 backdrop-blur-sm backdrop-opacity-80">
          <Button variant="secondary" onClick={props.prev} text="Previous" />
          <Button
            onClick={props.next}
            text={props.nextText ?? "Next"}
            disabled={!props.next}
          />
        </div>
      )}
    </div>
  );
}

function CodeSlide(props: {
  tag: string;
  title: string;
  storeKey: StoreKeys;
  description: string;
  next: () => void;
  prev: () => void;
}) {
  const [lang, setLang] = React.useState("typescript");
  const [defaultValue, onChange] = useStoreResult({
    ...props,
    kind: "code",
  });

  return (
    <Slide
      tag={props.tag}
      title={props.title}
      description={props.description}
      content={
        <div className="flex flex-col items-end">
          <Editor
            height="50vh"
            defaultValue={defaultValue ?? "// your solution here"}
            language={lang}
            theme="vs-dark"
            onChange={(v) => onChange(v, { lang })}
          />
          <select
            className="w-full max-w-md shadow-sm rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 mt-2 leading-8 transition-colors duration-200 ease-in-out"
            value={lang}
            onChange={(e) => setLang(e.target.value)}
          >
            <option value="typescript">TypeScript</option>
            <option value="python">Python</option>
            <option value="java">Java</option>
            <option value="c#">C#</option>
            <option value="sql">SQL</option>
          </select>
        </div>
      }
      next={props.next}
      prev={props.prev}
    />
  );
}

function FreeTextSlide(props: {
  tag: string;
  title: string;
  description: string;
  storeKey: StoreKeys;
  next: () => void;
  prev: () => void;
}) {
  const isSSR = useIsSSR();
  const [defaultValue, onChange] = useStoreResult({ ...props, kind: "text" });

  if (isSSR) return null;

  const ReactQuill = require("react-quill");

  return (
    <Slide
      tag={props.tag}
      title={props.title}
      description={props.description}
      content={
        <div className="bg-white pb-[44px]">
          <ReactQuill
            theme="snow"
            className=" h-96"
            defaultValue={defaultValue}
            onChange={onChange}
          />
        </div>
      }
      next={props.next}
      prev={props.prev}
    />
  );
}

function InputSlide(props: {
  tag: string;
  title: string;
  description: string;
  type?: React.HTMLInputTypeAttribute;
  placeholder: string;
  storeKey: StoreKeys;
  next: () => void;
  prev: () => void;
}) {
  const [defaultValue, onChange] = useStoreResult({
    ...props,
    kind: props.type ?? "text",
  });

  return (
    <Slide
      tag={props.tag}
      title={props.title}
      description={props.description}
      content={
        <input
          type={props.type}
          defaultValue={defaultValue}
          placeholder={props.placeholder}
          onChange={(e) => onChange(e.target.value)}
          className="w-full max-w-5xl shadow-sm rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mt-1 leading-8 transition-colors duration-200 ease-in-out"
        />
      }
      next={props.next}
      prev={props.prev}
    />
  );
}

function ChoiceSlide(props: {
  tag: string;
  title: string;
  description: string;
  options: { value: string; label: string }[];
  multiple?: boolean;
  storeKey: StoreKeys;
  next: () => void;
  prev: () => void;
}) {
  const [defaultValue, onChange] = useStoreResult<string[]>({
    ...props,
    kind: "choice",
    meta: {
      choices: props.options.map((o) => o.value),
    },
  });
  const [selected, setSelected] = React.useState<string[]>([]);

  React.useEffect(() => setSelected(defaultValue), [defaultValue]);

  return (
    <Slide
      tag={props.tag}
      title={props.title}
      description={props.description}
      content={
        <div className="flex flex-col space-y-3 max-w-3xl">
          {props.options.map((option) => {
            const isSelected = selected.includes(option.value);

            return (
              <button
                key={option.value}
                onClick={() => {
                  if (props.multiple) {
                    const newValues = selected.includes(option.value)
                      ? selected.filter((v) => v !== option.value)
                      : [...selected, option.value];
                    setSelected(newValues);
                    onChange(newValues);
                  } else {
                    const newValues = selected.includes(option.value)
                      ? []
                      : [option.value];
                    setSelected(newValues);
                    onChange(newValues);
                  }
                }}
                className={twMerge(
                  "px-4 py-3 border rounded-md text-left transition-colors",
                  isSelected
                    ? "border-indigo-500 bg-indigo-50 text-indigo-700 shadow-sm"
                    : "border-gray-300 hover:border-indigo-300 hover:bg-indigo-50/30",
                )}
              >
                {option.label}
              </button>
            );
          })}
        </div>
      }
      next={props.next}
      prev={props.prev}
    />
  );
}

function Timer(props: { remainingSeconds: number }) {
  const minutes = Math.floor(props.remainingSeconds / 60);
  const seconds = props.remainingSeconds % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  let colorClass = "bg-white";
  if (minutes < 5) {
    colorClass = "bg-red-500 text-white animate-shake";
  } else if (minutes < 20) {
    colorClass = "bg-yellow-400";
  }

  const emoji = minutes <= 0 ? "💀" : minutes < 5 ? "⏰" : "⏲️";

  return (
    <div
      className={twMerge(
        `absolute top-4 right-4 p-2 rounded shadow font-semibold transition-colors duration-300 z-50`,
        colorClass,
      )}
    >
      {emoji}
      <span className="ml-4">{formattedTime}</span>
    </div>
  );
}

export default function CandidateTest({ location }) {
  const isSSR = useIsSSR();
  const { candidateTest } = useConfig();
  const pushGtmEvent = usePushGtmEvent();

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [startedAt, setStartedAt] = React.useState<number | null>(null);
  const [remainingSeconds, setRemainingSeconds] = React.useState<number>(
    MAX_MINUTES * 60,
  );
  const [showTimer, setShowTimer] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const { token, name } = React.useMemo(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("t");
    if (!token) {
      if (!isSSR) navigate("/");
      return {} as never;
    }
    const decoded = jwtDecode<{ name: string }>(token);
    return { token, name: decoded.name };
  }, [isSSR, location.search]);

  React.useEffect(
    () =>
      setStartedAt(
        localStorage.getItem("testStartTime")
          ? parseInt(localStorage.getItem("testStartTime")!)
          : null,
      ),
    [],
  );

  React.useEffect(
    () =>
      pushGtmEvent({
        event: GtmEven.candidateTestView,
      }),
    [],
  );

  React.useEffect(() => {
    if (startedAt) {
      setShowTimer(true);

      const interval = setInterval(() => {
        const elapsed = Math.floor((Date.now() - startedAt) / 1000);
        const remaining = MAX_MINUTES * 60 - elapsed;

        if (remaining < 0) {
          alert(
            `Time's up ${name}! You didn't finish the test in time. You may want to write us an email to explain what happened if you like`,
          );
          clearInterval(interval);
        } else if (remaining <= 5 * 60) {
          alert(`You have 5 minutes left! Hurry up ${name}!`);
        }

        setRemainingSeconds(Math.max(0, remaining));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [startedAt]);

  React.useEffect(() => {
    const submitted = localStorage.getItem("submitted");
    if (submitted) {
      navigate("/join/thanks");
    }
  }, []);

  React.useEffect(() => {
    pushGtmEvent({
      event: GtmEven.candidateTestStep,
      step: currentIndex,
    });
    if (currentIndex === START_TIMER_SLIDE_INDEX) {
      setShowTimer(true);
    } else if (currentIndex > START_TIMER_SLIDE_INDEX && !startedAt) {
      const now = Date.now();
      setStartedAt(now);
      localStorage.setItem("testStartTime", now.toString());
    }
  }, [currentIndex]);

  const goNext = () => {
    if (currentIndex < slides.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const goPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const submit = () => {
    const responses = StoreKeys.map((key) => localStorage.getItem(key));
    if (responses.some((r) => !r))
      return alert(
        "🧩 Oops! Looks like you missed some puzzle pieces. Please complete all questions before submitting your test!",
      );

    if (
      confirm(
        "🚀 Ready to launch your test into the review orbit? This action is final! 🎮",
      )
    ) {
      setSubmitting(true);
      pushGtmEvent({ event: GtmEven.candidateTestSubmit });
      fetch(candidateTest.endpoint, {
        method: "POST",
        body: JSON.stringify({
          token,
          responses: responses.map((r) => (r ? JSON.parse(r) : null)),
        }),
      })
        .then((res) => {
          if (!res.ok) throw new Error("Failed to submit test");

          localStorage.setItem("submitted", "true");
          alert(
            "🚀 Congratulations! Your test has been successfully submitted. Thank you for your time and effort!",
          );
          navigate("/join/thanks");
        })
        .catch(() => {
          alert(
            "🚨 Something went wrong! Your test wasn't submitted. Please try again or contact us directly.",
          );
        })
        .finally(() => setSubmitting(false));
    }
  };

  const slides = [
    <Slide
      tag="Astrorei"
      title={`Welcome to Astrorei, ${name}! 🚀`}
      description={`<strong>Ready for a technical adventure?</strong><br><br>This assessment is designed to showcase your programming skills and problem-solving abilities in a structured yet engaging way.<br><br>Each challenge has been carefully crafted to explore different aspects of your technical expertise. Take time to understand each problem before coding your solution.<br><br>Use any built-in functions or data structures that make sense for your approach. <strong>We value clean code, efficiency, and thoughtful implementation.</strong><br><br>Let's see what you can do!`}
      next={goNext}
      prev={goPrev}
      dark
    />,

    <Slide
      tag="Astrorei"
      title={`Warm-up Session`}
      description={`<strong>Before diving into code, let's learn about your technical background.</strong><br><br>The following questions will help us understand your experience, preferences, and career aspirations.<br><br>Ready to begin?`}
      next={goNext}
      prev={goPrev}
    />,

    <ChoiceSlide
      storeKey="General1"
      tag="General"
      title="Developer Identity 🧑‍💻"
      description="Which development role best describes your expertise and interests?"
      options={[
        { value: "backend", label: "Backend Developer 🧠" },
        { value: "frontend", label: "Frontend Developer 🎨" },
        { value: "fullstack", label: "Fullstack Developer 🚀" },
      ]}
      next={goNext}
      prev={goPrev}
    />,

    <ChoiceSlide
      storeKey="General2"
      tag="General"
      title="Tech Stack Preferences"
      description="Which technology ecosystem do you feel most comfortable working with? (Multiple Choice)"
      options={[
        { value: "js", label: "Node/Typescript" },
        { value: "python", label: "Python" },
        { value: "java", label: "Java" },
        { value: "c#", label: "C#" },
        { value: "SQL", label: "SQL" },
        { value: "other", label: "Other" },
      ]}
      multiple
      next={goNext}
      prev={goPrev}
    />,

    <ChoiceSlide
      storeKey="General3"
      tag="General"
      title="Cloud Platform Experience"
      description="Which cloud environment do you prefer or have the most experience with?"
      options={[
        { value: "AWS", label: "AWS" },
        { value: "GCP", label: "GCP" },
        { value: "Azure", label: "Azure" },
        { value: "Vercel", label: "Vercel" },
        { value: "Other", label: "Other" },
        { value: "None", label: "New to cloud, but eager to learn!" },
      ]}
      multiple
      next={goNext}
      prev={goPrev}
    />,

    <ChoiceSlide
      storeKey="General4"
      tag="General"
      title="Development Environment 🖥️"
      description="Which operating system do you prefer for development work?"
      options={[
        { value: "mac", label: "Team Apple 🍏" },
        { value: "windows", label: "Team Microsoft 🪟" },
        { value: "linux", label: "Team Linux 🐧" },
        { value: "all", label: "Comfortable across all platforms 🤓" },
      ]}
      next={goNext}
      prev={goPrev}
    />,

    <InputSlide
      storeKey="General5"
      tag="General"
      title="Compensation Expectations 💰"
      description="Let's address the practical side of things. What annual salary range (RAL) are you looking for in this position?"
      placeholder="e.g. 40'000"
      type="number"
      next={goNext}
      prev={goPrev}
    />,

    <Slide
      tag="Astrorei"
      title="Code Challenge Time! 🛠️"
      description={`<strong>Now for the technical portion of our journey!</strong><br><br>The following challenges will test your problem-solving skills, code quality, and algorithmic thinking.<br><br>Each exercise evaluates different aspects of software development expertise. Read each problem carefully before starting your solution.<br><br> Remember, you have <strong>${MAX_MINUTES} minutes</strong> to complete all challenges. Ready to code?`}
      next={goNext}
      prev={goPrev}
      dark
    />,

    <CodeSlide
      storeKey="ArrayCount"
      tag="Coding"
      title="The FooBar Challenge 🎲"
      description={`<strong>Create a function that outputs numbers from 1 to 100 with these rules:</strong><br/><br/>• Replace multiples of 3 with <strong>"Foo"</strong><br/>• Replace multiples of 5 with <strong>"Bar"</strong><br/>• Replace multiples of both 3 and 5 with <strong>"FooBar"</strong><br/><br/>Focus on readability and efficiency in your solution!`}
      next={goNext}
      prev={goPrev}
    />,

    <CodeSlide
      storeKey="SystemDesign"
      tag="Coding"
      title="Optimized Array Comparison 📊"
      description="<strong>Array Analysis Problem:</strong> Given two arrays of lengths n and k with elements in range [0, 1], for each element in the second array, count how many elements in the first array are smaller than it.<br/><br/><strong>Challenge:</strong> Implement a solution better than O(n²) time complexity.<br/><br/>Show us your algorithmic optimization skills! 🧩"
      next={goNext}
      prev={goPrev}
    />,

    <FreeTextSlide
      storeKey="Star1"
      tag="Attitude"
      title="Time Management Dilemma ⏳"
      description="Scenario: You have two critical tasks requiring 2 hours each, but only 3 hours available before deadline. How would you approach this situation to deliver the most effective outcome?"
      next={goNext}
      prev={goPrev}
    />,

    <FreeTextSlide
      storeKey="Star2"
      tag="Leadership"
      title="Initiative Beyond Scope 🚀"
      description="Describe a project where you took initiative beyond your defined responsibilities. What specific actions did you take, and what measurable impact did your extra effort create for the project outcome?"
      next={goNext}
      prev={goPrev}
    />,

    <FreeTextSlide
      storeKey="Star3"
      tag="Learning"
      title="Quick Technology Adaptation 🔍"
      description="Share an example where you needed to rapidly learn an unfamiliar technology under deadline pressure. What learning approach did you use, and how did you successfully apply this new knowledge?"
      next={goNext}
      prev={goPrev}
    />,

    <FreeTextSlide
      storeKey="Star4"
      tag="Problem Solving"
      title="Technical Challenge Resolution 💡"
      description="Describe your most significant technical challenge and how you overcame it. What systematic approach did you follow, what resources did you use, and what was the final solution you implemented?"
      next={goNext}
      prev={goPrev}
    />,

    <FreeTextSlide
      tag="design"
      storeKey="FooBar"
      title="Retail Discovery Platform Design 🏗️"
      description="<strong>Design a comprehensive system for a physical store discovery platform</strong> (similar to JustEat but for retail stores), including:<br/><br/>• Mobile applications (iOS/Android)<br/>• Consumer-facing web application<br/>• Management portal for store owners<br/><br/><strong>Key objectives:</strong> Balance quality with development speed and operational efficiency.<br/><br/>Detail your technology choices for frontend and backend, considering that data will come from both web-scraping and manual entry. Explain the trade-offs in your architectural decisions."
      next={goNext}
      prev={goPrev}
    />,

    <Slide
      tag="Astrorei"
      title="Ready to Submit? 🚀"
      description="<strong>Final checkpoint before sending your work!</strong><br/><br/>Take a moment to review your solutions and ensure your code is clean, efficient, and well-commented.<br/><br/>Once submitted, your assessment will be reviewed by our technical team.<br/><br/><strong>Thank you for sharing your technical expertise with us today!</strong>"
      next={submit}
      nextText="Submit"
      prev={goPrev}
      dark
    />,
  ];

  if (!name) return null;

  return (
    <>
      <Helmet>
        <title>Astrorei Technical Assessment</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <img
        src={require("../../assets/images/Rei_2.png").default}
        className="hidden lg:block absolute bottom-24  h-24 -right-3 z-40 transition-transform duration-500 ease-in-out"
        style={{
          transform: `translateY(-${(currentIndex / (slides.length - 1)) * 75}vh) rotate(-90deg)`,
        }}
      />
      <div className="h-screen w-screen overflow-hidden bg-gray-50">
        {showTimer && <Timer remainingSeconds={remainingSeconds} />}
        <div
          ref={containerRef}
          className="flex h-full transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {slides}
        </div>
      </div>
      {submitting && (
        <div className="fixed inset-0 h-screen w-screen backdrop-blur-3xl backdrop-opacity-90 z-50">
          <div className="h-full w-full flex items-center justify-center backdrop-opacity-80 ">
            <img
              src={require("../../assets/images/Rei_1.png").default}
              className="h-32 animate-[spin_2s_linear_infinite]"
            />
          </div>
        </div>
      )}
    </>
  );
}
